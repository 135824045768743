.matrix {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 180px;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.box {
  width: 50px;
  height: 50px;
  border: 0.3px solid #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.box:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-left: 20px;
  
}